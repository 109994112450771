export const unlinkWiki = (string: string) => {
  const searchReg = /<a[^>]*href="[^"]*\/wiki\/[^"]*"[^>]*>/g;
  const match = string.match(searchReg);
  if (match) {
    const replace = "";
    let text = string.replace(searchReg, replace);
    text = text.replace("</a>", replace);
    return text;
  } else {
    return string;
  }
};
