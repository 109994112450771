export const defaultEase = [0.455, 0.03, 0.515, 0.955];

export const defaultTransition = {
  ease: defaultEase,
  duration: 1,
};

export const loader_variant = {
  loading: {
    rotate: [0, 270],
    transition: {
      ...defaultTransition,
      repeat: Infinity,
    },
  },
  idle: {
    rotate: 0,
    transition: defaultTransition,
  },
  hide: {
    scale: 0.8,
    opacity: 0,
    transition: defaultTransition,
  },
  onhover: {
    rotate: [0, 90],
    transition: {
      ease: [0.455, 0.03, 0.515, 0.955],
      duration: 0.5,
    },
  },
  endhover: {
    rotate: [90, 0],
    transition: {
      ease: [0.455, 0.03, 0.515, 0.955],
      duration: 0.5,
    },
  },
};

export const full_y_variant_default = {
  initial: {
    y: "100%",
  },
  animate: {
    y: 0,
    transition: defaultTransition,
  },
  exit: {
    y: "100%",
    transition: defaultTransition,
  },
};

export const y_variant = {
  initial: {
    y: 300,
    opacity: 0,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: defaultTransition,
  },
  exit: {
    y: 300,
    opacity: 0,
    transition: defaultTransition,
  },
};

export const stagger_variant = {
  animate: {
    transition: {
      ease: defaultEase,
      duration: 1,
      staggerChildren: 0.15,
    },
  },
  exit: {
    transition: {
      ease: defaultEase,
      duration: 1,
      staggerChildren: 0.15,
    },
  },
};

export const negative_y_variant = {
  initial: {
    y: "-100%",
  },
  animate: {
    y: 0,
    transition: defaultTransition,
  },
  exit: {
    y: "-100%",
    transition: defaultTransition,
  },
};

export const full_y_variant = {
  initial: {
    y: "150%",
  },
  animate: {
    y: 0,
    transition: defaultTransition,
  },
  exit: {
    y: "-150%",
    transition: defaultTransition,
  },
};

export const blur_opacity_variant = {
  initial: {
    opacity: 0,
    filter: "blur(10px)",
  },
  animate: {
    opacity: 1,
    filter: "blur(0px)",
    transition: defaultTransition,
  },
  exit: {
    opacity: 0,
    filter: "blur(10px)",
    transition: defaultTransition,
  },
};

export const scale_blur_variant = {
  initial: {
    scale: 1.5,
    filter: "blur(10px)",
  },
  animate: {
    scale: 1,
    filter: "blur(0px)",
  },
  exit: {
    scale: 1.5,
    filter: "blur(10px)",
  },
};

export const opacity_variant = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      ...defaultTransition,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ...defaultTransition,
    },
  },
};

export const line_variant = {
  initial: {
    x: "-110%",
  },
  animate: {
    x: 0,
    transition: {
      ease: defaultEase,
      duration: 1,
    },
  },
  exit: {
    x: "-110%",
    transition: {
      ease: defaultEase,
      duration: 1,
    },
  },
};

export const slide_variant = {
  initial: {
    scale: 0.9,
    x: 500,
    opacity: 0,
    filter: "blur(10px)",
  },
  animate: {
    scale: 1,
    x: 0,
    opacity: 1,
    filter: "blur(0px)",
    transition: {
      ...defaultTransition,
    },
  },
};

export const negative_slide_variant = {
  initial: {
    scale: 0.9,
    x: -500,
    opacity: 0,
    filter: "blur(10px)",
  },
  animate: {
    scale: 1,
    x: 0,
    opacity: 1,
    filter: "blur(0px)",
    transition: {
      ...defaultTransition,
    },
  },
};
