import { MenuItem } from "./MenuItem";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MenuType } from "../../../utils/baseTypes";

interface MainMenuProps {
  menu: MenuType;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
}

export type MenuItemActiveState = {
  toggle: boolean;
  currentIndex: number | null;
};

export const MainMenu = ({ menu, setMenuOpen }: MainMenuProps) => {
  useEffect(() => {
    function handleResize() {
      setIsActive({
        toggle: false,
        currentIndex: null,
      });
      if (window.innerWidth >= 1408) {
        setMenuOpen(false);
      }
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isActive, setIsActive] = useState<MenuItemActiveState>();

  return (
    <ul className="main-menu" role="menubar" aria-label="Hauptmenü">
      {menu.items.map((element, i) => {
        return (
          <MenuItem
            element={element}
            setMenuOpen={setMenuOpen}
            index={i}
            key={i}
            isActive={isActive}
            setIsActive={setIsActive}
          />
        );
      })}
    </ul>
  );
};
