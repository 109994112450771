import { ComponentProps, ButtonHTMLAttributes } from "react";
import { FadeInFromBottom, FadeInWithPulse } from "../../utils/Animation";
import ArrowIcon from "../../images/arrow.svg";
import { getRelativePathFromLink } from "../../utils/url";
import { CustomLink } from "./CustomLink";
import { unlinkWiki } from "../../utils/unlinkWiki";

export type ButtonTypes = "primary" | "secondary";

interface ButtonProps extends ComponentProps<"a"> {
  type: ButtonTypes;
  animation?: "fade" | "pulse" | "none";
  title: string;
  disablePrefetch?: boolean;
}

export const Button = ({
  type,
  animation,
  title,
  disablePrefetch,
  ...props
}: ButtonProps) => {
  const isBlank = props.target === "" || !props.target ? "" : "noopener";
  const hasTarget = props.target === "" || !props.target ? "_self" : "_blank";
  const buttonClasses = props.className ? props.className : "";
  if (!props.href) props.href = "#";

  const innerButtonJSX =
    type === "primary" ? (
      <CustomLink
        href={props.href}
        target={hasTarget}
        rel={isBlank}
        className={`btn is-${type} ${buttonClasses}`}
        disablePrefetch={disablePrefetch}
      >
        <div />
        <div />
        {unlinkWiki(title)}
        <div />
        <div />
      </CustomLink>
    ) : (
      <CustomLink
        href={getRelativePathFromLink(props.href)}
        // href={props.href}
        target={hasTarget}
        rel={isBlank}
        className={`btn is-${type} ${buttonClasses}`}
        disablePrefetch={disablePrefetch}
      >
        <div className="is-clipped">
          <span>{unlinkWiki(title)}</span>
          <ArrowIcon />
        </div>
      </CustomLink>
    );

  let buttonJSX;
  switch (animation) {
    case "fade":
      buttonJSX = <FadeInFromBottom>{innerButtonJSX}</FadeInFromBottom>;
      break;
    case "pulse":
      buttonJSX = <FadeInWithPulse>{innerButtonJSX}</FadeInWithPulse>;
      break;
    default:
      buttonJSX = innerButtonJSX;
      break;
  }

  return buttonJSX;
};

interface SimpleButtonProp extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

export const SimpleButton = ({ text, ...props }: SimpleButtonProp) => {
  return (
    <button {...props}>
      <div />
      <div />
      {unlinkWiki(text)}
      <div />
      <div />
    </button>
  );
};
