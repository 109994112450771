import Link from "next/link";
import { Dispatch, SetStateAction } from "react";
import { MenuType, OptionsType } from "../../utils/baseTypes";
import { getRelativePathFromLink } from "../../utils/url";

interface MenuProps {
  menu: MenuType;
  showCompany?: boolean;
  options?: OptionsType;
  setShowConsentManagement?: Dispatch<SetStateAction<boolean>>;
  menuName?: string;
}

export const Menu = ({
  menu,
  showCompany,
  options,
  setShowConsentManagement,
  menuName,
}: MenuProps) => {
  const currentYear = new Date(Date.now()).getFullYear();
  return (
    <div
      id="legal-menu"
      role="menubar"
      aria-label="legal-menu"
      className="legal-menu"
    >
      {showCompany && (
        <span>&copy;&nbsp;ALEKS &amp; SHANTU&nbsp;{currentYear} </span>
      )}
      {menu.items.map((item, index) => (
        <Link
          prefetch={false}
          key={index}
          href={getRelativePathFromLink(item.url, item.type)}
          target={item.target}
          className="legal menu-item is-uppercase"
          id={`${menu.name}-menuitem-${index}-${menuName ?? ""}`}
          role="menuitem"
        >
          <span>{item.title}</span>
        </Link>
      ))}
      {options != null && setShowConsentManagement != null ? (
        <span
          id="menuitem-privacysettings"
          className="legal menu-item is-uppercase"
          onClick={() => setShowConsentManagement(true)}
          style={{ cursor: "pointer" }}
        >
          {/* FIXME: This should be a label */}
          <span>{"COOKIE CONSENT"}</span>
        </span>
      ) : null}
    </div>
  );
};
