import Link from "next/link";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { getRelativePathFromLink } from "../../../utils/url";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import ArrowIcon from "../../../images/arrow.svg";
import { MenuItemType } from "../../../utils/baseTypes";
import { MenuItemActiveState } from "./MainMenu";

interface MenuItemProps {
  index: number;
  element: MenuItemType;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
  isActive?: MenuItemActiveState;
  setIsActive: Dispatch<SetStateAction<MenuItemActiveState | undefined>>;
}

export const MenuItem = ({
  element,
  index,
  setMenuOpen,
  isActive,
  setIsActive,
}: MenuItemProps) => {
  const router = useRouter();
  const myRef = useRef<HTMLLIElement>(null);

  const isMobile = useMediaQuery("(max-width: 767px)");
  const [isTouchDevice, setIsTouchDevice] = useState<boolean | null>(null);

  useEffect(() => {
    setIsTouchDevice("ontouchstart" in window);
    // ? || navigator.msMaxTouchPoints <-- This was in the setState, where does it come from?
    const checkIfClickedOutside = () => {
      if (
        myRef.current &&
        isActive?.toggle &&
        isActive.currentIndex === index
      ) {
        if (myRef.current.matches(":hover") === false) {
          setIsActive({ toggle: false, currentIndex: null });
        }
      }
    };
    if (!isMobile) {
      isActive?.toggle && isActive.currentIndex === index
        ? document.addEventListener("mousedown", checkIfClickedOutside)
        : document.removeEventListener("mousedown", checkIfClickedOutside);
    } else {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    }
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isActive, setIsActive, index, isMobile]);

  return (
    <li
      ref={myRef}
      role="menuitem"
      className={`${
        isActive?.toggle && isActive.currentIndex === index ? "is-active" : ""
      } ${element.classes[0] === "is-lang" ? "is-lang" : ""}
        ${element.classes[0] === "has-sub" ? "has-sub" : ""} ${
        element.child_items ? "has-sub" : ""
      }
        ${
          router.asPath + "/" === getRelativePathFromLink(element.url) ||
          router.asPath === getRelativePathFromLink(element.url) ||
          (router.asPath.startsWith("/digitalagentur/") &&
            getRelativePathFromLink(element.url) === "/digitalagentur/") || //select digitalagentur menu item for every digitalagentur subpage
          (router.asPath.startsWith("/project/") &&
            getRelativePathFromLink(element.url) === "/portfolio/") //select portfolio menu item for every project (sub)page
            ? "is-current"
            : ""
        }
        `}
    >
      {!element.child_items ? (
        <Link
          prefetch={false}
          href={getRelativePathFromLink(element.url)}
          onClick={() => setMenuOpen(false)}
          id={`main-menu-${index + 1}`}
          aria-haspopup="false"
          className={`
            ${element.classes[0] === "is-lang" ? "is-lang" : ""} 
            ${element.classes[0] === "has-sub" ? "has-sub" : ""}
            ${
              router.asPath + "/" === getRelativePathFromLink(element.url) ||
              router.asPath === getRelativePathFromLink(element.url)
                ? "is-current"
                : ""
            }
            `}
          /*className={`cursor-pointer }*/
        >
          {element.title}
        </Link>
      ) : (
        <span
          onClick={() => {
            isActive &&
              setIsActive({ toggle: !isActive.toggle, currentIndex: index });
          }}
          className={`a sub-title ${
            router.asPath.indexOf(element.post_name) !== -1 ? "is-current" : ""
          }
          `}
          id={`main-menu-${index + 1}`}
        >
          {element.title}
          <div className="is-hidden-fullhd">
            <ArrowIcon />
          </div>
        </span>
      )}
      {element.child_items
        ? (!isTouchDevice || isActive?.currentIndex === index) && (
            <ul className="sub-menu">
              {element.child_items.map((link, i) => {
                return (
                  <li
                    key={i}
                    onClick={() => {
                      setIsActive({ toggle: false, currentIndex: null });
                      setMenuOpen(false);
                    }}
                    role="menuitem"
                    className={` ${
                      router.asPath + "/" ===
                        getRelativePathFromLink(element.url) ||
                      router.asPath === getRelativePathFromLink(element.url)
                        ? "is-current"
                        : ""
                    }`}
                  >
                    <Link
                      href={getRelativePathFromLink(link.url)}
                      prefetch={false}
                    >
                      <a
                        id={`main-menu-${index + 1}`}
                        aria-haspopup="false"
                        className={`
                      ${element.classes[0] === "is-lang" ? "is-lang" : ""} 
                      
                    `}
                      >
                        {link.title}
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )
        : null}
    </li>
  );
};
