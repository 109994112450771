import React from "react";
import Link from "next/link";
import { ComponentProps } from "react";
import { LinkNoPrefetch } from "./LinkNoPrefetch";
import { optimizeURL } from "../../utils/url";
// import { useRouter } from "next/router";

interface LinkProps extends ComponentProps<"a"> {
  href: string;
  children: React.ReactNode;
  target: string;
  /**
   * Creates a link with no prefetching abilities... which Next Links per default have
   */
  disablePrefetch?: boolean;
  /**
   * Creates a link which triggers prefetching only on hover... Next Links prefetch per default as soon as link is visible
   */
  onlyPrefetchOnHover?: boolean;
  className?: string;
}

export const CustomLink = ({
  children,
  href,
  target,
  disablePrefetch = false,
  onlyPrefetchOnHover = false,
  className,
}: LinkProps) => {
  const rel = target === "_blank" ? "noopener noreferrer" : "";
  // const { asPath } = useRouter();
  const optimizedHref = optimizeURL(href);
  if (!optimizedHref || optimizedHref.length === 0) {
    // Return nothing if link is not set
    //return null;
    //return div wrapper around children with a class to allow styling
    // console.warn(`CustomLink without href at ${asPath}`);
    return <div className={`a ${className}`}>{children}</div>;
  }

  return disablePrefetch ? (
    <LinkNoPrefetch href={optimizedHref} className={className}>
      {children}
    </LinkNoPrefetch>
  ) : !onlyPrefetchOnHover ? (
    <Link
      href={optimizedHref}
      target={target}
      rel={rel}
      prefetch={false}
      className={className}
    >
      {children}
    </Link>
  ) : (
    <Link
      href={optimizedHref}
      target={target}
      rel={rel}
      className={className}
      prefetch={false}
    >
      {children}
    </Link>
  );
};
