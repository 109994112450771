import { Menu } from "./Menu";
import { sanitize } from "isomorphic-dompurify";
import { MenuType, OptionsType } from "../../utils/baseTypes";

import { Button } from "../Global/Button";
import Reviews from "./Review";
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";

const Socials = dynamic(() => import("./Socials"), {
  loading: () => <></>,
  ssr: false,
});

const ConsentManagement = dynamic(
  () =>
    import("@aleksundshantu.com/aunds-cookie-plugin-react").then(
      (mod) => mod.ConsentManagement
    ),
  {
    loading: () => <></>, // Empty fallback while loading
    ssr: false, // Disable server-side rendering
  }
);

interface FooterProps {
  menu: MenuType;
  options: OptionsType;
  legalMenu: MenuType;
}

export const Footer = ({ options, menu, legalMenu }: FooterProps) => {
  const [showConsentManagement, setShowConsentManagement] = useState(false);
  const [renderConsentManagement, setRenderConsentManagement] = useState(false);

  useEffect(() => {
    const handleUserAction = () => {
      setRenderConsentManagement(true); // Trigger consent management on user interaction
      // Remove all event listeners after the first interaction
      window.removeEventListener("scroll", handleUserAction);
      window.removeEventListener("click", handleUserAction);
      window.removeEventListener("mousemove", handleUserAction);
      window.removeEventListener("keydown", handleUserAction);
      window.removeEventListener("touchstart", handleUserAction);
      window.removeEventListener("focusin", handleUserAction);
    };

    // Add various interaction event listeners
    window.addEventListener("scroll", handleUserAction);
    window.addEventListener("click", handleUserAction);
    window.addEventListener("mousemove", handleUserAction);
    window.addEventListener("keydown", handleUserAction);
    window.addEventListener("touchstart", handleUserAction);
    window.addEventListener("focusin", handleUserAction);

    // Cleanup function to remove all event listeners if the component unmounts
    return () => {
      window.removeEventListener("scroll", handleUserAction);
      window.removeEventListener("click", handleUserAction);
      window.removeEventListener("mousemove", handleUserAction);
      window.removeEventListener("keydown", handleUserAction);
      window.removeEventListener("touchstart", handleUserAction);
      window.removeEventListener("focusin", handleUserAction);
    };
  }, []);

  const {
    plattform,
    newsletter_title,
    newsletter_text,
    newsletter_link,
    reviews,
  } = options.acf;

  return (
    <footer
      className="footer has-dark-grey-bg"
      aria-label="footer"
      id="kontakt"
    >
      <div className="columns container">
        <div className="column is-12-tablet">
          <div className="columns is-centered socials-column">
            <Socials platforms={plattform} />
          </div>
          <div className="columns is-centered second">
            <div className="column legal-menu-row is-4-desktop is-hidden-touch">
              <Menu menu={menu} menuName="top" />
            </div>
            <div className="column news-letter-row is-4-desktop">
              <div className="news-letter">
                <span className="title">
                  <strong>{newsletter_title}</strong>
                </span>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(newsletter_text),
                  }}
                ></div>
                <Button
                  className="lightBorder mt-5 footer-button"
                  animation="none"
                  type="primary"
                  title={newsletter_link.title}
                  href={newsletter_link.url}
                  target={newsletter_link.target}
                />
              </div>
            </div>
            <div className="column is-4-desktop is-hidden-touch">
              <div className="review-wrapper">
                {reviews.map((review, i) => {
                  return <Reviews key={i} review={review} />;
                })}
              </div>
            </div>
          </div>
          <div className="columns touch-menu is-hidden-desktop">
            <div className="column legal-menu-column is-12 is-hidden-desktop">
              <Menu menu={menu} menuName="touch-top" />
            </div>
          </div>
          <div className="columns is-centered third">
            <div className="legal-menu-column">
              <Menu
                menu={legalMenu}
                showCompany
                options={options}
                menuName="bottom"
                setShowConsentManagement={setShowConsentManagement}
              />
            </div>
          </div>
          <div className="is-hidden-desktop">
            <div className="review-wrapper">
              {reviews.map((review, i) => {
                return <Reviews key={i} review={review} />;
              })}
            </div>
          </div>
        </div>
      </div>
      {renderConsentManagement && (
        <ConsentManagement
          wpApiUrl={process.env.WP_API_URL!}
          reloadOnSave
          setIsForceShow={setShowConsentManagement}
          isForceShow={showConsentManagement}
        />
      )}
    </footer>
  );
};
