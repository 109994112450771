import React from "react";
import { useRouter } from "next/router";

/**
 * https://github.com/vercel/next.js/discussions/24009#discussioncomment-4393284
 */

interface LinkNoPrefetchProps {
  href: string;
  children: React.ReactNode;
  className?: string;
}

export const LinkNoPrefetch = React.forwardRef<
  HTMLAnchorElement,
  LinkNoPrefetchProps
>(({ href, children, className }, ref) => {
  const router = useRouter();

  const onClick = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    await router.push(href);
  };

  return (
    <a ref={ref} href={href} onClick={onClick} className={className}>
      {children}
    </a>
  );
});

LinkNoPrefetch.displayName = "LinkNoPrefetch";
