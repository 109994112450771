import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useMediaQuery } from "../hooks/useMediaQuery";
import useWindowSize from "../hooks/useWindowSize";

const transition_ = {
  ease: [0.455, 0.03, 0.515, 0.955],
  duration: 1,
};

export const FadeInFromBottom = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <motion.span
      className="button-fade-in-bottom"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.4 }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
      }}
    >
      {children}
    </motion.span>
  );
};

export const FadeInImage = ({ children }: { children: React.ReactNode }) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.4 }}
      transition={{ ...transition_, delay: 0.5 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0.3 },
      }}
    >
      {children}
    </motion.div>
  );
};

export const FadeInFromLeft = ({ children }: { children: React.ReactNode }) => {
  const [variantsLeft, setVariantsLeft] = useState({
    visible: {
      opacity: 1,
      y: 0,
      x: 0,
    },
    hidden: {
      opacity: 0,
      x: -200, //TODO: change animation from mobile to Desktop, now not working useEffect on Desktop.
      y: 0, // Real values: x: 0, y: 200
    },
  });
  const { width } = useWindowSize();
  const isMobile = width <= 767;
  useEffect(() => {
    if (!isMobile) {
      setVariantsLeft({
        visible: {
          opacity: 1,
          x: 0,
          y: 0,
        },
        hidden: {
          opacity: 0,
          x: -200,
          y: 0,
        },
      });
    }
  }, [isMobile]);

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.4 }}
      transition={transition_}
      variants={variantsLeft}
    >
      {children}
    </motion.div>
  );
};

export const FadeInFromRight = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { width } = useWindowSize();
  const isTouch = width < 1024;
  const variantsRight = isTouch
    ? {
        visible: {
          opacity: 1,
          y: 0,
          x: 0,
        },
        hidden: {
          opacity: 0,
          x: 0,
          y: 200,
        },
      }
    : {
        visible: {
          opacity: 1,
          x: 0,
          y: 0,
        },
        hidden: {
          opacity: 0,
          y: 0,
          x: 200,
        },
      };
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.4 }}
      transition={transition_}
      variants={variantsRight}
    >
      {children}
    </motion.div>
  );
};

export const FadeInWithPulse = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <motion.span
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.5 }}
      transition={{ duration: 0.7 }}
      variants={{
        visible: { opacity: [1, 1, 1], scale: [1, 1.2, 1], y: [0, 0, 0] },
        hidden: { opacity: 0, scale: 1, y: 100 },
      }}
    >
      {children}
    </motion.span>
  );
};

interface FadeAnimationsProp {
  children: React.ReactNode;
  animationType: "fade_from_left" | "fade_from_right";
}

export const FadeAnimations = ({
  children,
  animationType,
}: FadeAnimationsProp) => {
  let animtionJSX = <></>;
  switch (animationType) {
    case "fade_from_left":
      animtionJSX = <FadeInFromLeft>{children}</FadeInFromLeft>;
      break;
    case "fade_from_right":
      animtionJSX = <FadeInFromRight>{children}</FadeInFromRight>;
      break;
    default:
      animtionJSX = <FadeInFromLeft>{children}</FadeInFromLeft>;
      break;
  }

  return animtionJSX;
};
