import { useEffect, useState } from "react";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";

import { Logo } from "./Logo/Logo";
import { MainMenu } from "./Menu/MainMenu";
import { MenuType, OptionsType, PageType } from "../../utils/baseTypes";
import { getRelativePathFromLink } from "../../utils/url";
import Aunds from "./Aunds";
import AundsLogo from "./Aunds/TouchLogo.svg";
const Hamburger = dynamic(() => import("./Hamburger"), { ssr: false });

import LanguageSeparator from "../../images/navbar/aunds-language-sererator.svg";
import useWindowSize from "../../hooks/useWindowSize";
import { defaultEase } from "../../utils/transition";
import { LanguageToggle } from "./Language/LanguageToggle";
import { useRouter } from "next/router";
import { sanitize } from "isomorphic-dompurify";
import dynamic from "next/dynamic";

interface NavbarProps {
  menu: MenuType;
  options: OptionsType;
  className?: string;
  defaultBg?: boolean;
  page: PageType;
}

const variants = {
  open: { opacity: 1, y: 0, height: "100%", zIndex: -1 },
  closed: { opacity: 0, y: "-100%", height: "0px" },
};
const button_variants = {
  open: { opacity: 1, height: "100%", width: "100%" },
  closed: { opacity: 0, height: 0, width: 0 },
};

export const Navbar = ({ options, menu, page }: NavbarProps) => {
  const { width } = useWindowSize();

  const [showHamburger, setshowHamburger] = useState<boolean>(true);
  const [reachedTop, setReachedTop] = useState<boolean>(false);
  const [animation, setAnimation] = useState<string>(
    width <= 1023 ? "closed" : "open"
  );
  const envLocalesArray = process.env.LOCALES?.split(",") ?? ["de"];
  const hasMoreThanOneLocale = envLocalesArray.length > 1;

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [translatedPageLink, setTranslatedPageLink] = useState<string>("");
  const [hoverContact, setHoverContact] = useState<boolean>(false);

  const activeLocal = page.wpml_current_locale === "de_DE" ? "de" : "en";
  const translatedPageLinkURL =
    page.wpml_translations && page.wpml_translations.length
      ? page.wpml_translations[0].href
      : undefined;

  const title = options.acf.titel;
  const position = options.acf.funktion;

  useEffect(() => {
    if (translatedPageLinkURL) {
      setTranslatedPageLink(
        window.location.origin + getRelativePathFromLink(translatedPageLinkURL)
      );
    }
  }, [translatedPageLinkURL]);

  const { scrollY } = useScroll();

  const scrollToggleNav = (latest: number) => {
    if (window.innerWidth > 1023) {
      if (latest >= 200) {
        setAnimation("closed");

        setshowHamburger(true);
      } else {
        setAnimation("open");

        setshowHamburger(false);
        setReachedTop(true);
      }
    }
  };

  useMotionValueEvent(scrollY, "change", (latest: number) => {
    scrollToggleNav(latest);
  });

  useEffect(() => {
    if (width <= 1023) {
      setshowHamburger(true);
      setAnimation("closed");
    } else {
      setshowHamburger(false);
      setAnimation("open");
    }
  }, [width]);

  const { events } = useRouter();

  useEffect(() => {
    const handler = () => {
      if (window.innerWidth < 1023) {
        setAnimation("closed");
      }
    };
    events.on("routeChangeComplete", handler);
    return () => {
      events.off("routeChangeComplete", handler);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header className={animation === "open" ? "" : "hidden-menu"}>
      <nav className="navbar-custom">
        <div className="navbar-top">
          <div className="nav-left">
            <motion.button
              variants={button_variants}
              animate={showHamburger ? "open" : "closed"}
              transition={{ ease: defaultEase, duration: 0.8 }}
              className="hamburger-button"
              aria-label="hamburger-button"
              onClick={() => {
                if (animation === "open") {
                  setAnimation("closed");
                } else {
                  setAnimation("open");
                }
              }}
            >
              <Hamburger
                animation={animation}
                reachedTop={reachedTop}
                setReachedTop={setReachedTop}
                showHamburger={showHamburger}
              />
            </motion.button>

            <Logo options={options} />
            <div className="logo-text">
              <h3>{title}</h3>
              <p>{position}</p>
            </div>
          </div>
          <div className="nav-right is-hidden-mobile">
            <LanguageToggle page={page} type="clickable" />
            <div className="text is-hidden-touch last">
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitize(options.acf.header_text),
                }}
              ></span>
            </div>
            <div className="contact is-hidden-mobile">
              <a
                className="is-capitalized"
                href={options.acf.header_contact_link.url}
                target={options.acf.header_contact_link.target}
                onMouseEnter={() => {
                  setHoverContact(true);
                }}
                onMouseLeave={() => {
                  setHoverContact(false);
                }}
              >
                {options.acf.header_contact_link.title} &nbsp;
                <Aunds
                  width={40}
                  height={30}
                  color={"#fff"}
                  isHover={hoverContact}
                />
              </a>
            </div>
          </div>
        </div>
        <motion.div
          animate={animation}
          variants={variants}
          transition={{
            easings: defaultEase,
          }}
          initial={width <= 1023 ? "closed" : "open"}
        >
          <div className="navbar-bottom-wrapper">
            <div className="navbar-bottom">
              <MainMenu menu={menu} setMenuOpen={setMenuOpen} />
              <div>
                <LanguageToggle
                  page={page}
                  type="clickable"
                  extraClasses="is-hidden-desktop"
                />
                <div className="text is-hidden-desktop">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: sanitize(options.acf.header_text),
                    }}
                  ></span>
                </div>
                <div className="contact is-hidden-desktop">
                  <a
                    className="is-capitalized"
                    href={options.acf.header_contact_link.url}
                    target={options.acf.header_contact_link.target}
                  >
                    {options.acf.header_contact_link.title} &nbsp;
                    <AundsLogo width={40} height={30} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </nav>
    </header>
  );
};
