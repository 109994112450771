import Head from "next/head";
import { OptionsType, PageType } from "../../utils/baseTypes";
import { getRelativePathFromLink, setTrailingSlash } from "../../utils/url";

interface HeadMetaProps {
  post: PageType;
  options: OptionsType;
}

export const HeadMeta = ({ post, options }: HeadMetaProps) => {
  const yoast = post?.yoast_head_json;
  const title = (yoast?.title ?? post?.title?.rendered).replace("&amp;", "&");
  const shareImage = options?.acf.share_image;

  return (
    <Head>
      <link rel="dns-prefetch" href={process.env.WP_API_URL} />
      <link rel="preconnect" href={process.env.WP_API_URL} />
      <link
        rel="preconnect"
        href={process.env.WP_API_URL}
        crossOrigin="anonymous"
      />
      <title>{title}</title>
      {/*
      // ! Add this in the consent management backend plugin
      <script
        async
        src="https://platform.twitter.com/widgets.js"
        charSet="utf-8"
      ></script> */}

      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content={yoast?.description} />
      {/* Meta Name Robots */}
      {yoast?.robots && (
        <meta
          name="robots"
          content={[
            yoast?.robots.index,
            yoast?.robots.follow,
            yoast?.robots["max-image-preview"],
            yoast?.robots["max-snippet"],
            yoast?.robots["max-video-preview"],
          ].toString()}
        />
      )}
      {/* only include og:image tags if page has og_image or default share image is set in theme options */}
      {(yoast?.og_image?.[0] || shareImage) && (
        <>
          <meta
            property="og:image"
            content={
              yoast?.og_image?.[0]
                ? yoast.og_image[0].url
                : shareImage
                ? shareImage?.sizes?.large
                : ""
            }
          />
          <meta
            property="og:image:width"
            content={
              yoast?.og_image?.[0]
                ? yoast.og_image[0].width.toString()
                : shareImage
                ? shareImage.sizes["large-width"].toString()
                : ""
            }
          />
          <meta
            property="og:image:height"
            content={
              yoast?.og_image?.[0]
                ? yoast.og_image[0].height.toString()
                : shareImage
                ? shareImage?.sizes["large-height"].toString()
                : ""
            }
          />
        </>
      )}
      {/* Meta Property OG LOCALE CONTENT */}
      <meta property="og:locale" content={yoast?.og_locale} />
      {/* Meta Property OG TYPE*/}
      <meta property="og:type" content={yoast?.og_type} />
      {/* Meta Property OG TITLE */}
      <meta
        property="og:title"
        content={yoast?.og_title.replace("&amp;", "&")}
      />
      {/* Meta Property OG DESCRIPTION */}
      <meta property="og:description" content={yoast?.og_description} />
      {/* Meta Property OG URL */}
      {yoast?.og_url && <meta property="og:url" content={yoast?.og_url} />}
      {/* Meta Property OG SITE_NAME */}
      <meta
        property="og:site_name"
        content={yoast?.og_site_name?.replace("&amp;", "&")}
      />
      {/* Meta Property ARTICLE:MODIFIED_TIME  */}
      {yoast?.article_modified_time && (
        <meta
          property="article:modified_time"
          content={yoast?.article_modified_time}
        />
      )}
      {yoast?.canonical && (
        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_PUBLIC_URL}${setTrailingSlash(
            getRelativePathFromLink(yoast.canonical)
          )}`}
        />
      )}
      {/* Meta Name */}
      <meta name="twitter_card" content={yoast?.twitter_card} />
      {/* Script */}
      <script type="application/ld+json" className="yoast-schema-graph">
        {JSON.stringify(yoast?.schema)}
      </script>
    </Head>
  );
};
