import Image from "next/image";
import { Review } from "../../utils/baseTypes";

import Star from "./Star";

interface ReviewProps {
  review: Review;
}

const Reviews = ({ review }: ReviewProps) => {
  return (
    <a href={review.link.url} target={review.link.target}>
      <div className="review">
        <div className="logo-container">
          <Image
            src={review.image.url as string}
            height={35}
            width={35}
            alt={review.image.alt}
            data-title={review.image.title}
            sizes="(max-width: 768px) 50vw, (max-width: 1023px) 25vw, (max-width: 1440px) 15vw, 10vw"
          />
        </div>
        <div className="content">
          <div className="title">
            <span>{review.title}</span>
          </div>
          <div className="rating">
            <span>
              {parseFloat(review.stars_image.replace(",", ".")).toFixed(1)}
            </span>
            <div className="stars">
              {[...Array(parseInt(review.stars))].map((_, i) => {
                const stars = parseFloat(review.stars_image.replace(",", "."));
                const isHalf = stars % 1 === 0.5 && i === Math.trunc(stars);
                return (
                  <Star
                    width="17"
                    height="17"
                    color="#e7711b"
                    opacityOfStar={i > stars - 1 && !isHalf ? "50%" : "100%"}
                    isHalf={isHalf}
                    indexOfStar={i}
                    key={i}
                  />
                );
              })}
            </div>
          </div>
          <div className="review-numbers">
            <span>{review.reviews_number}</span>
          </div>
        </div>
      </div>
    </a>
  );
};

export default Reviews;
