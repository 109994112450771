interface AundsProps {
  color: string;
  width: number;
  height: number;
  isHover?: boolean;
}

const Aunds = ({ color, width, height, isHover }: AundsProps) => {
  return (
    <svg
      id="Ebene_1"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 100 100"
    >
      <defs>
        <linearGradient
          id="gradient_color"
          x1="-2.00435"
          y1="6.18458"
          x2="98.98851"
          y2="107.17745"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#0741a2" />
          <stop offset="0.1" stopColor="#0741a2" />
          <stop offset="0.2" stopColor="#4b3da0" />
          <stop offset="0.3" stopColor="#8b399d" />
          <stop offset="0.4" stopColor="#c3359b" />
          <stop offset="0.5" stopColor="#f13199" />
          <stop offset="0.6" stopColor="#f5308c" />
          <stop offset="0.7" stopColor="#ea3074" />
          <stop offset="0.8" stopColor="#df305a" />
          <stop offset="0.9" stopColor="#d33141" />
          <stop offset="1" stopColor="#ce3136" />
        </linearGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_11" data-name="Layer 11">
          <path
            id="aund"
            d="M79.27225,75.23932,93.20659,59.00391,82.72388,50.10639C77.50809,56.67725,65.21009,71.25078,59.58522,77.387c-5.16465,5.62487-11.531,8.59071-18.17855,8.66741-9.40887.10227-17.18142-5.36919-17.28369-13.55082v-.28124c-.0767-6.23849,4.6533-12.60482,13.602-16.59336l18.7666,18.6899C58,72.73369,59.86646,70.68829,61.8863,68.36164c1.78973-2.071,3.37492-4.09081,4.4999-5.5993L52.47743,48.95585c12.80936-5.01125,21.17-12.70709,21.01656-25.46531V23.2093C73.34058,10.1954,62.83231-.0572,47.46619.12177c-17.33482.2301-27.74083,11.71-27.58742,24.72384v.28125c.0767,7.05665,2.659,13.11617,8.28389,20.12168C14.94423,51.078,7.7086,60.18,7.862,72.63142v.28125c.20454,16.74676,13.90876,27.40844,32.03617,27.2039,11.63325-.12784,21.27223-4.98568,29.47943-12.86049l13.03946,12.86049L93.20659,89.12251ZM35.83294,25.357c-.0767-6.77541,4.5766-11.68439,11.47984-11.76109,6.36633-.0767,10.99406,4.29535,11.07077,10.81508v.28125C58.46025,31.621,53.27,36.24876,43.32424,39.95605c-5.47146-5.88054-7.44016-9.46-7.51687-14.31784Z"
            fill={isHover ? color : "url(#gradient_color)"}
          />
        </g>
        s
      </g>
    </svg>
  );
};

export default Aunds;
