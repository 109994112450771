import { useRouter } from "next/router";
import { PageType } from "../../../utils/baseTypes";
import { getWPMLTranslationsForPage } from "../../../utils/language";
import { getRelativePathFromLink } from "../../../utils/url";
import LanguageSeperator from "../../../images/navbar/aunds-language-sererator.svg";
import { useEffect, useState } from "react";
import Link from "next/link";

interface LanguageToggleProps extends React.HTMLAttributes<HTMLDivElement> {
  page?: PageType;
  type: "hoverable" | "clickable";
  extraClasses?: string;
}

export const LanguageToggle = ({
  page,
  extraClasses = "",
}: LanguageToggleProps) => {
  const router = useRouter();
  const { asPath, locale } = router;
  const envLocalesArray = process.env.LOCALES?.split(",") ?? ["de"];

  const translatedPageLinksArray = getWPMLTranslationsForPage(router, page);

  // FIXME before pushing
  const hasMoreThanOneLocale = envLocalesArray.length > 1;

  const translatedUrlsInitialState = translatedPageLinksArray.map(
    (translation: string) => {
      const linkArray = getRelativePathFromLink(translation)
        .split("/")
        .filter((element) => element !== "");

      return process.env.PUBLIC_URL + "/" + linkArray.join("/");
    }
  );

  const [open, toggleOpen] = useState<boolean>(false);
  const [translatedPageLink, setTranslatedPageLink] = useState(
    translatedUrlsInitialState
  );
  const activeLocal = page?.wpml_current_locale === "de_DE" ? "de" : "en";

  useEffect(() => {
    if (translatedPageLinksArray) {
      setTranslatedPageLink(
        translatedPageLinksArray.map((translation: string) => {
          const arr = getRelativePathFromLink(translation)
            .split("/")
            .filter((element) => element !== "");

          return window.location.origin + "/" + arr.join("/");
        })
      );
    }

    toggleOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, locale]);

  const languageToggleJSX = (
    <div className={`text ${extraClasses}`}>
      <span className={`de ${activeLocal === "de" ? "active-lang" : ""}`}>
        <Link
          prefetch={false}
          href={`${activeLocal !== "de" ? translatedPageLink : "/"}`}
          // TODO add label from options
          aria-label="Language Toggle"
          title="Language Toggle"
        >
          DE
        </Link>
      </span>
      <span className="line">
        <LanguageSeperator width={45} />
      </span>
      <span className={`en ${activeLocal === "en" ? "active-lang" : ""}`}>
        <Link
          prefetch={false}
          href={`${activeLocal !== "en" ? translatedPageLink : "/"}`}
          // TODO add label from options
          aria-label="Language Toggle"
          title="Language Toggle"
        >
          EN
        </Link>
      </span>
    </div>
  );

  return <>{hasMoreThanOneLocale && languageToggleJSX}</>;
};
